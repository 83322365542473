import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import styles from "./Info.module.css";

const Info = () => {
  const data = useStaticQuery(graphql`
    query {
      workouts: file(relativePath: { eq: "workouts-view.png" }) {
        childImageSharp {
          fluid(maxWidth: 988, maxHeight: 520) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photo1: file(relativePath: { eq: "info-photo-1.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 662, maxHeight: 441) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      photo2: file(relativePath: { eq: "info-photo-2.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 662, maxHeight: 441) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={styles.Info}>
      <div className={styles.InfoWorkouts}>
        <Img
          fluid={data.workouts.childImageSharp.fluid}
          alt="Workouts screenshot"
        />
      </div>
      <div className={styles.InfoContent}>
        <div className={styles.InfoTitle}>
          What is <span className={styles.InfoFitLab}>FitLab?</span>
        </div>
        <div className={styles.InfoText}>
          FitLab is a personal trainer app to track clients and to build
          training programs. It optimizes the cooperation of the trainer and the
          client. The app makes a connection between you more comfortable for
          both sides.
        </div>
        <div className={styles.InfoBottomText}>FitLab is made for</div>
        <div className={styles.InfoBottom}>
          <div className={styles.InfoBlock}>
            <div className={styles.InfoBlockImageWrapper}>
              <Img
                className={styles.InfoBlockImage}
                fluid={data.photo1.childImageSharp.fluid}
                alt="First photo"
              />
            </div>
            <div className={styles.InfoBlockTitle}>
              Personal Fitness Trainers
            </div>
          </div>
          <div className={styles.InfoBlock}>
            <div className={styles.InfoBlockImageWrapperFixed}>
              <Img
                className={styles.InfoBlockImage}
                fluid={data.photo2.childImageSharp.fluid}
                alt="second photo"
              />
            </div>
            <div className={styles.InfoBlockTitle}>Online Coaches</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
